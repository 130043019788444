<template>

  <div
      class="h-screen dark:bg-gray-900 bg-white text-black dark:text-white flex justify-center flex sm:flex-col xl:flex-row sm:p-5 xl:p-0">
    <div class="sw:w-full xl:w-1/3 sm:hidden xl:block">
      <div class="w-full h-full dark:bg-black bg-gray-300 flex justify-center content-center items-center">
        <img class="max-h-72" v-show="dark" src="@/assets/img/iso/main-dark.svg" alt="software">
        <img class="max-h-72" v-show="!dark" src="@/assets/img/iso/main.svg" alt="software">
      </div>
    </div>
    <div class="sm:w-full lg:w-2/3 flex justify-center items-center lg:container mx-auto">
      <div class="sm:p-0 xl:p-24 sm:w-full xl:w-2/3">
        <h1 class="text-5xl mb-5">Регистрация</h1>
        <div v-show="!Object.keys(message).length" class="dark:bg-gray-800 dark:shadow-none shadow-2xl rounded p-5">
          <input placeholder="Имя*" v-model="form.name">
          <small class="text-red-600"
                 v-if="!$v.form.name.required && $v.form.name.$dirty">{{ $t('forms.validation.required') }}</small>
          <input placeholder="Фамилия*" v-model="form.surname">
          <small class="text-red-600"
                 v-if="!$v.form.surname.required && $v.form.surname.$dirty">{{
              $t('forms.validation.required')
            }}</small>

          <input placeholder="Отчество" v-model="form.second_name">
          <small class="text-red-600"
                 v-if="!$v.form.second_name.required && $v.form.second_name.$dirty">{{
              $t('forms.validation.required')
            }}</small>

          <input placeholder="Телефон*" v-model="form.personal_phone">
          <small class="text-red-600"
                 v-if="!$v.form.personal_phone.required && $v.form.personal_phone.$dirty">{{
              $t('forms.validation.required')
            }}</small>


          <input placeholder="Email*" v-model.trim="form.email">
          <small class="text-red-600"
                 v-if="!$v.form.email.required && $v.form.email.$dirty">{{ $t('forms.validation.required') }}</small>
          <small class="text-red-600"
                 v-if="!$v.form.email.email && $v.form.email.$dirty">{{ $t('forms.validation.required') }}</small>
          <input placeholder="Пароль" type="password" v-model.trim="form.password">
          <small class="text-red-600"
                 v-if="!$v.form.password.required && $v.form.password.$dirty">{{
              $t('forms.validation.required')
            }}</small>
          <input placeholder="Подтверждение пароля" type="password" v-model.trim="form.passwordCheck">
          <small class="text-red-600"
                 v-if="!$v.form.passwordCheck.required && $v.form.passwordCheck.$dirty">{{
              $t('forms.validation.required')
            }}</small>
          <div class="flex justify-between mt-2 items-center">
            <div class="w-4/12 rounded">
              <img v-if="captchaImageLink.length" :src="captchaImageLink" alt="Белтаможсервис">
            </div>
            <button @click.prevent="getCaptcha" class="reload"><i class="fas fa-redo "></i></button>

            <input class="captcha" placeholder="Captcha" type="text" v-model="form.captchaValue">
          </div>
          <div class="flex items-center mt-2">
            <div>
              <input name="personalDataAgreement" class="h-5 mr-5" type="checkbox" v-model="form.personalDataAgreement">
            </div>
            <label class="text-sm">
              Даю согласие РУП "Белтаможсервис" на обработку моих персональных данных на <a class="text-blue-500" target="_blank"
                            href="https://declarant.by/upload/policy/personal_data_policy.pdf">заявленных условиях*</a>
            </label>
            <small class="text-red-600"
                   v-if="!$v.form.personalDataAgreement.sameAsTrue && $v.form.personalDataAgreement.$dirty">{{
                $t('forms.validation.required')
              }}</small>

          </div>

          <div class="flex justify-center mt-8">
            <button :disabled="form.personalDataAgreement === false"
                    :class="form.personalDataAgreement === true ? 'dark:bg-green-400': 'dark:bg-gray-600'"
                    class="px-10 py-2 rounded"
                    @click.prevent="submit">Зарегистрироваться
            </button>
          </div>

        </div>
        <div v-show="Object.keys(message).length" class="dark:bg-gray-800 dark:shadow-none shadow-2xl rounded p-5">
          <div class="bg-green-400 mb-5 p-5 rounded">
            {{ message.status }}
          </div>
          <div class="bg-blue-400 mb-5 p-5 rounded">
            {{ message.info }}
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

export default {
  meta: {
    title: 'Регистрация'
  },
  computed: {
    dark() {
      return this.$store.getters['darkTheme']
    }
  },
  name: "Register",
  data: () => ({
    form: {
      name: '',
      surname: '',
      second_name: '',
      personal_phone: '',
      email: '',
      password: '',
      passwordCheck: '',
      captchaValue: '',
      captchaCode: '',
      personalDataAgreement: false
    },
    captchaImageLink: '',
    message: {}
  }),
  created() {
    this.getCaptcha()
  },
  methods: {
    async getCaptcha() {
      let res = await this.$store.dispatch('auth/captcha')
      this.form.captchaCode = res.data.data.code
      this.captchaImageLink = res.data.data.img
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store.dispatch('auth/register', this.form).then(res => {
          this.message = res.data.data
        }).catch(err => {
              this.getCaptcha()
              this.form.captchaValue = ''
              this.$notify({
                title: 'Ошибка',
                text: err.response.data.message,
                type: 'error'
              })
            }
        )

      }


    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {required},
      surname: {required},
      second_name:{required},
      personal_phone: {required},
      email: {required, email},
      password: {required},
      passwordCheck: {required},
      captchaValue: {required},
      personalDataAgreement: {
        sameAsTrue: value => value === true
      }
    },
  }

}
</script>

<style scoped>
input {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  rounded bg-gray-200   dark:bg-gray-700
  dark:focus:bg-gray-900 outline-none  dark:text-gray-300
  dark:focus:text-white;
}

input.captcha {
  @apply w-7/12;
}

.background {
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0 100%);
}

.reload:active {
  transform: rotate(180deg);
  animation-timing-function: ease-in;
  animation-duration: 1s;
  transition: all;
}
</style>
